import "./PaymentList.css"
import {useEffect, useState} from "react"
import {NavigateFunction, useNavigate} from "react-router-dom"
import Button from "../../../component/button/Button"
import fetchWithRetry, {CancelFunction} from "../../../util/request"
import Payment from "./payment/Payment"
import Currency from "../../store/currency/Currency"

export interface PaymentRecord {
    uuid: string
    sms_code?: string
    sms_info?: {
        number: string
        price: string
    }
    date: number
    currency: string
    state: "PENDING" | "COMPLETED" | "CANCELED" | "REFUNDED"
    total: number
    item_count: number
    items: {
        category: string
        item: string
        gift?: string
        gift_name?: string
        price: number
    }[]
    currency_multiplied: number
}

const RETURN_URL = "/account/payments"

function loadPayments(
    after: number | null,
    count: number | null,
    token: string,
    navigate: NavigateFunction,
    callback: (content: PaymentRecord[], total: number, currencies: {
        [key: string]: Currency
    }) => void): CancelFunction {
    const urlParams: { [key: string]: string } = {}

    if (after) urlParams.after = after.toString()
    if (count) urlParams.count = count.toString()

    return fetchWithRetry(res => {
        if (res.status === 401) {
            navigate("/login?return=" + RETURN_URL)
            return true;
        }
        if (!res.ok) return false;

        return res.json().then((body: {
            payments: PaymentRecord[],
            currencies: { [key: string]: Currency }
        }) => {
            callback(body.payments, parseInt(res.headers.get("Total-Payments") ?? "0"), body.currencies);

            return true;
        })
    }, process.env.REACT_APP_API_BASE + "/store/payments?" + new URLSearchParams(urlParams), {
        headers: {
            Authorization: token
        }
    })
}

export default function PaymentList(props: {
    token: string
}) {
    const {token} = props;

    const [payments, setPayments] = useState<PaymentRecord[]>([]);
    const [currencies, setCurrencies] = useState<{ [key: string]: Currency } | null>(null);
    const [totalPayments, setTotalPayments] = useState(-1);
    const [sending, setSending] = useState(false);

    const navigate = useNavigate()

    function addPayments(payments: PaymentRecord[], total: number, currencies: { [key: string]: Currency }) {
        setPayments(prevState => {
            const copy = [...prevState];
            for (let payment of payments) {
                if (!copy.some(v => v.uuid === payment.uuid))
                    copy.push(payment);
            }
            return copy;
        })
        setTotalPayments(total);
        setCurrencies(currencies);
    }

    useEffect(() => {
        return loadPayments(null, 10, token, navigate, addPayments);
    }, [navigate, token])

    const nextPageSize = Math.min(totalPayments - payments.length, 10);

    let content: any;

    if (payments.length > 0 && currencies !== null) {
        content = payments.map(v => <Payment payment={v} currencies={currencies} token={token} key={v.uuid}/>);
    } else if (totalPayments >= 0) {
        content = <p>Nejsou zde žádné platby</p>
    }

    if (content === null || currencies === null)
        content = <div className={"loader big center margin"}/>

    return <div className={"payment-list"}>
        {content}
        {payments.length > 0 && nextPageSize > 0 ? <Button loading={sending} onClick={() => {
            setSending(true);
            loadPayments(payments[payments.length - 1].date, nextPageSize, token, navigate, (paymentRecords, total, serverCurrencies) => {
                addPayments(paymentRecords, total, serverCurrencies);
                setSending(false);
            });
        }}>Načíst dalších {nextPageSize}</Button> : null}
    </div>
}